import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Listing`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/broadcast-contacts/listing-panel/"
        }}>{`Broadcast Contacts`}</a>{` - This panel lists all the contacts that were added to the broadcast.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts-attempts/listing-panel/"
        }}>{`Contacts Attempts`}</a>{` - This panel lists all the call attempts made by the agents.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contact-center-activity/listing-panel/"
        }}>{`Contact Center Activity`}</a>{` - This panel lists all the contact center activities.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts/listing-panel/"
        }}>{`Contacts`}</a>{` - This panel lists all the contacts.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ecommerce/listing-panel/"
        }}>{`ECommerce`}</a>{` - This panel lists all the shopping carts data.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/events/listing-panel/"
        }}>{`Events`}</a>{` - This panel lists all the events.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr/listing-panel/"
        }}>{`IVR`}</a>{` - This panel lists all the IVRs.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/leads/listing-panel/"
        }}>{`Leads`}</a>{` - This panel lists all the leads.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-actions/listing-panel/"
        }}>{`Outbound Campaigns Actions`}</a>{` - This panel lists all the outbound campaigns actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-email/listing-panel/"
        }}>{`Outbound Campaigns Email`}</a>{` - This panel lists all the outbound campaigns email.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-push-actions/listing-panel/"
        }}>{`Outbound Campaigns Push Actions`}</a>{` - This panel lists all the outbound campaigns push actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-push/listing-panel/"
        }}>{`Outbound Campaigns Push`}</a>{` - This panel lists all the outbound campaigns push.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-sms/listing-panel/"
        }}>{`Outbound Campaigns SMS`}</a>{` - This panel lists all the outbound campaigns SMS.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-voice/listing-panel/"
        }}>{`Outbound Campaigns Voice`}</a>{` - This panel lists all the outbound campaigns voice.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visitors/listing-panel/"
        }}>{`Visitors`}</a>{` - This panel lists all the visitors.`}</li>
    </ul>
    <h2>{`Aggregation`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts-attempts/aggregation-panel-metrics/"
        }}>{`Contacts Attempts`}</a>{` - This panel lists all the call attempts made by the agents.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/canned-messages/aggregation-panel-metrics/"
        }}>{`Canned Messages`}</a>{` - This panel lists all the canned messages that are available in the system.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/chatbot-stats/aggregation-panel-metrics/"
        }}>{`Chatbot Stats`}</a>{` - This panel lists all the chatbot statistics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/chatbot/aggregation-panel-metrics/"
        }}>{`Chatbot`}</a>{` - This panel lists all the chatbot interactions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contact-center-activity/aggregation-panel-metrics/"
        }}>{`Contact Center Activity`}</a>{` - This panel lists all the contact center activities.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts-sales/aggregation-panel-metrics/"
        }}>{`Contacts Sales`}</a>{` - This panel lists all the contacts that were added to the sales.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts/aggregation-panel-metrics/"
        }}>{`Contacts`}</a>{` - This panel lists all the contacts.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/content/aggregation-panel-metrics/"
        }}>{`Content`}</a>{` - This panel lists all the content feedback.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/conversions/aggregation-panel-metrics/"
        }}>{`Conversions`}</a>{` - This panel lists all the conversions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ecommerce/aggregation-panel-metrics/"
        }}>{`ECommerce`}</a>{` - This panel lists all the shopping carts data.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/events/aggregation-panel-metrics/"
        }}>{`Events`}</a>{` - This panel lists all the events.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr-nodes/aggregation-panel-metrics/"
        }}>{`IVR Nodes`}</a>{` - This panel lists all the IVR nodes.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr/aggregation-panel-metrics/"
        }}>{`IVR`}</a>{` - This panel lists all the IVRs.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/leads/aggregation-panel-metrics/"
        }}>{`Leads`}</a>{` - This panel lists all the leads.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/onsite-campaigns/aggregation-panel-metrics/"
        }}>{`Onsite Campaigns`}</a>{` - This panel lists all the onsite campaigns.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-actions/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Actions`}</a>{` - This panel lists all the outbound campaigns actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-email/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Email`}</a>{` - This panel lists all the outbound campaigns email.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-push-actions/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Push Actions`}</a>{` - This panel lists all the outbound campaigns push actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-sms/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns SMS`}</a>{` - This panel lists all the outbound campaigns SMS.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-voice/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Voice`}</a>{` - This panel lists all the outbound campaigns voice.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/segments-actions/aggregation-panel-metrics/"
        }}>{`Segments Actions`}</a>{` - This panel lists all the segments actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visitors/aggregation-panel-metrics/"
        }}>{`Visitors`}</a>{` - This panel lists all the visitors.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visits/aggregation-panel-metrics/"
        }}>{`Visits`}</a>{` - This panel lists all the visits.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/voice-stats/aggregation-panel-metrics/"
        }}>{`Voice Stats`}</a>{` - This panel lists all the voice statistics.`}</li>
    </ul>
    <h2>{`Metric`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts-attempts/metrics/"
        }}>{`Contacts Attempts`}</a>{` - This panel lists all the call attempts made by the agents.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/canned-messages/metrics/"
        }}>{`Canned Messages`}</a>{` - This panel lists all the canned messages that are available in the system.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/chatbot-stats/metrics/"
        }}>{`Chatbot Stats`}</a>{` - This panel lists all the chatbot statistics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/chatbot/metrics/"
        }}>{`Chatbot`}</a>{` - This panel lists all the chatbot interactions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contact-center-activity/metrics/"
        }}>{`Contact Center Activity`}</a>{` - This panel lists all the contact center activities.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts-sales/metrics/"
        }}>{`Contacts Sales`}</a>{` - This panel lists all the contacts that were added to the sales.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts/metrics/"
        }}>{`Contacts`}</a>{` - This panel lists all the contacts.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/content/metrics/"
        }}>{`Content`}</a>{` - This panel lists all the content feedback.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/conversions/metrics/"
        }}>{`Conversions`}</a>{` - This panel lists all the conversions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ecommerce/metrics/"
        }}>{`ECommerce`}</a>{` - This panel lists all the shopping carts data.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/events/metrics/"
        }}>{`Events`}</a>{` - This panel lists all the events.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr-nodes/metrics/"
        }}>{`IVR Nodes`}</a>{` - This panel lists all the IVR nodes.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr/metrics/"
        }}>{`IVR`}</a>{` - This panel lists all the IVRs.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/leads/metrics/"
        }}>{`Leads`}</a>{` - This panel lists all the leads.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/onsite-campaigns/metrics/"
        }}>{`Onsite Campaigns`}</a>{` - This panel lists all the onsite campaigns.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-actions/metrics/"
        }}>{`Outbound Campaigns Actions`}</a>{` - This panel lists all the outbound campaigns actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-email/metrics/"
        }}>{`Outbound Campaigns Email`}</a>{` - This panel lists all the outbound campaigns email.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-push-actions/metrics/"
        }}>{`Outbound Campaigns Push Actions`}</a>{` - This panel lists all the outbound campaigns push actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-sms/metrics/"
        }}>{`Outbound Campaigns SMS`}</a>{` - This panel lists all the outbound campaigns SMS.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-voice/metrics/"
        }}>{`Outbound Campaigns Voice`}</a>{` - This panel lists all the outbound campaigns voice.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/segments-actions/metrics/"
        }}>{`Segments Actions`}</a>{` - This panel lists all the segments actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visitors/metrics/"
        }}>{`Visitors`}</a>{` - This panel lists all the visitors.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visits/metrics/"
        }}>{`Visits`}</a>{` - This panel lists all the visits.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/voice-stats/metrics/"
        }}>{`Voice Stats`}</a>{` - This panel lists all the voice statistics.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      